import create from 'zustand'

export const customerObj = {
  "customer": {
      "action": "Other-AuthOcapi",
      "queryString": "c_app=true&c_wishlist=cb2dffa92a733efd12ccb16551&client_id=62bc8b34-0ca9-45a9-97e8-1888042da4e4&ocapi_version=v19_5",
      "locale": "de_DE",
      "_v": "19.5",
      "_type": "customer",
      "auth_type": "registered",
      "creation_date": "2020-04-20T13:20:16.000Z",
      "customer_id": "acSysQe5eJSeh8RacnM4WQuGWL",
      "customer_no": "00189518",
      "email": "tlp@demodern.de",
      "enabled": true,
      "first_name": "Timo",
      "last_login_time": "2021-11-11T19:36:25.085Z",
      "last_modified": "2021-11-11T19:36:26.791Z",
      "last_name": "Langpeter",
      "last_visit_time": "2021-11-11T19:36:25.085Z",
      "login": "tlp@demodern.de",
      "previous_login_time": "2021-11-11T19:36:25.085Z",
      "previous_visit_time": "2021-11-11T19:36:25.085Z",
      "title": "Herr",
      "c_loyaltyActive": true,
      "c_loyaltyCardID": "16032422997000200",
      "c_loyaltyRegistrationVerified": true,
      "c_loyaltySites": "nl,de",
      "c_loyaltyUserInfo": "{\"de\":{\"loyaltyCardID\":\"16032422997000200\",\"loyaltyActive\":true,\"loyaltyRegistrationVerified\":true,\"cvcCode\":842886,\"transitionalStatus\":{\"loyaltyCardId\":\"16032422997000200\",\"coins\":7795,\"conversionFactor\":0.01,\"maxDiscountFactor\":0.7,\"hearts\":450,\"coinsPending\":0,\"loyaltyRank\":\"CREW\",\"accountStatus\":\"ACTIVE\",\"cvcCode\":842886},\"lastCheckLoyaltyRank\":1636659386791,\"connectionError\":false},\"nl\":{\"isRefusedToBeLoyaltyMember\":false,\"howManyTimesPopupWasShown\":1}}",
      "c_preferredPaymentMethod": "CREDIT_CARD",
      "c_sscSyncResponseText": ["Waiting for update SC UUID", "Successfully Exported"],
      "c_sscSyncStatus": "exported",
      "c_sscid": "0037a00001KjHinAAF",
      "c_customerGroups": ["DE", "Everyone", "NativeApp", "NativeApp_DE", "PICK_UP_COLOGNE", "Registered", "app_and_loyalty_allmembers", "loyalty_all_members", "loyalty_crew_member", "test_cologne"],
      "JWT": "Bearer eyJfdiI6IjEiLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfdiI6IjEiLCJleHAiOjE2MzY2NjExODcsImlhdCI6MTYzNjY1OTM4NywiaXNzIjoiNjJiYzhiMzQtMGNhOS00NWE5LTk3ZTgtMTg4ODA0MmRhNGU0Iiwic3ViIjoie1wiX3ZcIjpcIjFcIixcImN1c3RvbWVyX2luZm9cIjp7XCJjdXN0b21lcl9pZFwiOlwiYWNTeXNRZTVlSlNlaDhSYWNuTTRXUXVHV0xcIixcImd1ZXN0XCI6ZmFsc2UsXCJndWVzdF9jdXN0b21lcl9pZFwiOlwiYWN3MDBROU1OMGFJZFpOcjdWUGxKYjZOUktcIixcInZpc2l0X2lkXCI6XCIzYTM1YjU0NmQ5YjU0NjVlMjY0MjkwYzllNFwifX0ifQ.dl8XgldAVo8SGRDrrSAdnbD_tnRnfYwIrohjhsPW78JgTif2kukQqnB74RgKHRx6U5CTBee8ktTVwqnmtguRT8X-CG4Ls8ddQCW5FyIjblpP5a6h1yrojXOJBMVK9CKrCdo-7H3HdEBe7ygmuuka63gIbxWQAVrKAOdzk8OzvlTR5cFk7v3MUQGBSW61oEOJbKLltjB3yo9YnFD6SXL_iHqLdDjY0rnsgVJSNhwNy4B6Y2NpG_mOX_gOmYm_m7hJzKuY4zU90SGc-GYkbBKfRPK3GthTr0LNXVsknydirpsZDI1hlBjrCxNz689-ogult6UvP1FBYWXdTrPFlGoqpT631psfeE8vPMRP25GepfOaecZs5-Sk2K0xZmJDePX_3AKxCA1ThkkSq_6OsXroLeFzY3FpSzwYY007CE43orEwBKrMr_G6j10I-Pq3tdpMNybTAboVlB8oSoEv0U4voJFpR88estj-5fNOjuiuTWXn4sd4ReQQkRcFeI0o7OUSpMSAmkeeJzWRVjAWn9t4h8HxzQGWubotKbaBAkM11EWBiQY6LEFNe3LMdQufz-AWAyl3vdkK2bV3sOltrtwNO8noJbTX5y83VD85gtCFdLD2uo1KHvZNoSiujJovpN7_15YEP3KhFSrqG_nkvKhOoi1WlUPweoL7HjuvjeQlUaE",
      "success": true,
      "cookie": "__cq_dnt=1; dw_dnt=1; dwsid=k5gmjRsg-dyiPvrOWC3S6-xu3OJ45dwJGjNHfsA0yg3mddewCV7jyMkE9VsaYb8f8pl_C4vTJ6XnibxmjeOsWw==; dwanonymous_f489fce3a63fde16454e4c1b6c85eee9=acw00Q9MN0aIdZNr7VPlJb6NRK; sid=8EbS8nYWq5F6OOTUojYxMLzvqH9OFoKJQuQ,dwcustomer_f489fce3a63fde16454e4c1b6c85eee9=acSysQe5eJSeh8RacnM4WQuGWL; Version=1; Comment=\"Demandware customer cookie for site Sites-snse-DE-AT-Site\"; Max-Age=0; Expires=Thu, 01-Jan-1970 00:00:10 GMT; Path=/; Secure; SameSite=None, dwsid=k5gmjRsg-dyiPvrOWC3S6-xu3OJ45dwJGjNHfsA0yg3mddewCV7jyMkE9VsaYb8f8pl_C4vTJ6XnibxmjeOsWw==; Path=/; Secure; HttpOnly; SameSite=None, __cq_dnt=1; Path=/; Secure; SameSite=None, dw_dnt=1; Path=/; Secure; SameSite=None"
  },
  navigator: (view:string) => console.log('Go to ', view)
}

const URL_REQUEST = { 
  'STG': 'https://stg.ugc.appsocial.snipes.com/v2/mcdonalds-campaign/',
  'PROD': 'https://ugc.appsocial.snipes.com/v2/mcdonalds-campaign/'
}

const APP_URL = { 
  'STG': 'stg.mcdonalds-campaign.appsocial.snipes.com',
  'PROD': 'mcdonalds-campaign.appsocial.snipes.com'
}

type RequestData = {
  urlRequest: string,
  prize: {
    status: string,
    type: string,
    code: string
  },
  couponTxt: string,
  prizeOptions: {
    [key: string]: string[]; 
  },
  JWToken: string,
  requestDone: boolean,
  setRequestDone:() => void, 
  setJWToken: (token:string) => void,
  requestPrize: () => void,
  requestSecondCall: () => void,
  setURLRequest: (debug:boolean) => void
}

export const useRequestData = create<RequestData>((set,get) => ({
  urlRequest: URL_REQUEST.PROD,
  requestDone: false,
  prize: {
    status: "",
    type: "",
    code: ""
  },
  couponTxt: '',
  prizeOptions: {
    MCD_GOLD_CARD: ["McDONALD’s","GOLD CARD"],
    MCD_VOUCHER_10: ["McDONALD’s","10€ WERTSCHECK"],
    MCD_SHERPA_BUCKET_HAT: ["SNIPES x McDONALD’s","SHERPA BUCKET HAT"],
    MCD_STRIPE_SCARF: ["SNIPES x McDONALD’s","STRIPE SCARF"],
    MCD_BEANIE: ["SNIPES x McDONALD’s","BEANIE"],
    MCD_LOGO_TEE: ["SNIPES x McDONALD’s","LOGO TEE"],
    MCD_HOODIE: ["SNIPES x McDONALD’s","HOODIE"],
    MCD_LUNCHBOX: ["SNIPES x McDONALD’s","LUNCHBOX"],
    SNI_VOUCHER_10: ["Voucher für","10% AUF ALLES* BEI SNIPES"],
    SNI_HOODIE: ["SNIPES x McDONALD’s","HOODIE"],
    SNI_BUCKET_HAT: ["SNIPES x McDONALD’s","BUCKET HAT"],
    SNI_GYM_BAG: ["SNIPES x McDONALD’s","GYM BAG"],
    SNI_WAIST_BAG: ["SNIPES x McDONALD’s","WAIST BAG"],
    SNI_BASEBALL_CAP: ["SNIPES BASEBALL CAP","BASEBALL CAP"]
  },
  JWToken: '',
  setRequestDone: () => {
    set({requestDone: true})
  },
  setJWToken: token => set({JWToken: token}),
  requestPrize: () => {
    const JWTtoken = get().JWToken;
    fetch(get().urlRequest,{
        headers: new Headers({
          'Authorization': JWTtoken, 
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .then(response => response.json())
      .then(data => {
        if(!data.detail) set({prize: data})
      }).catch(err=>{
        console.error(err)
      });
  },
  requestSecondCall: () => {
    fetch(get().urlRequest,{
        method: 'POST',
        headers: new Headers({
          'Authorization': get().JWToken, 
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.code) {
          set({couponTxt: data.code})
        }
      }).catch(err=>{
        console.error(err)
      });
  },
  setURLRequest: (debug) => {
    if (window.location.host === APP_URL.STG || debug) {
      set({urlRequest: URL_REQUEST.STG})
    }
  }
}))