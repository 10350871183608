import React, { CanvasHTMLAttributes, DetailedHTMLProps, FC, MouseEvent, useCallback, useMemo, useRef } from "react"
import brush from'../assets/brush.png';
import { useUIEvents } from "../service/uiService";
import { useRequestData } from "../service/requestService";

const BRUSH_SIZE = 40;

interface ScratchCanvasProps extends DetailedHTMLProps<CanvasHTMLAttributes<HTMLCanvasElement>,HTMLCanvasElement> {
    imagePricePath:string;
    imageScratchingPath:string;
}

export const ScratchCanvas:FC<ScratchCanvasProps> = ({imagePricePath,imageScratchingPath, width, height}) =>{
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasMask = useMemo(()=>{
        const c = document.createElement('canvas');
        c.setAttribute('style',`display= 'none';`);
        c.setAttribute('width',`${width}`);
        c.setAttribute('height',`${height}`);
        return c;
    }, [width, height]);
    const { FINISHED_UNCOVERED_PERCENTAGE, uncoveredPercentage, setUncoveredPercentage } = useUIEvents();
    const { prize } = useRequestData()

    const img:HTMLImageElement = useMemo(() => {
        const imgE:HTMLImageElement = new Image();
        if(imagePricePath)
          imgE.src = imagePricePath;
        return imgE
      }, [imagePricePath]) 

    const stencilS:HTMLImageElement[] = useMemo(() => {
        const stencilSrc = [brush]
        const stencils = []
        for (let i = 0; i < stencilSrc.length; i++) {
            const img:HTMLImageElement = new Image();
            img.src=stencilSrc[i];
            stencils.push(img);
        }
        
        return stencils
      }, []) 

      const drawImageScaled = (img: HTMLImageElement, ctx:CanvasRenderingContext2D) =>{
        var canvas = ctx.canvas ;
        var hRatio = canvas.width  / img.width    ;
        var vRatio =  canvas.height / img.height  ;
        var ratio  = Math.min ( hRatio, vRatio );
        var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
        var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
        // ctx.clearRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(img, 0,0, img.width, img.height,
                           centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  
     }

    const draw = useCallback((x: number, y:number)=>{
        if(canvasRef && canvasMask){
            const context = canvasRef.current?.getContext('2d');
            const maskContext = canvasMask.getContext('2d');
            if(context && maskContext){
                const image = stencilS[0];//stencilS[Math.floor(Math.random()*stencilS.length)]

                // Still draw to mask for uncover calculations!
                context.globalCompositeOperation = 'source-over';

                const halfBrushSize = BRUSH_SIZE/2;
                if(uncoveredPercentage < FINISHED_UNCOVERED_PERCENTAGE){
                    maskContext.drawImage(image, x-halfBrushSize, y-halfBrushSize, BRUSH_SIZE, BRUSH_SIZE);
                    context.drawImage(image, x-halfBrushSize, y-halfBrushSize, BRUSH_SIZE, BRUSH_SIZE)
                } else {
                    const canvas = canvasRef.current;
                    if(canvas){
                        context.fillRect(0,0,canvas.width, canvas.height)
                    }
                }
                context.globalCompositeOperation = 'source-in';
                drawImageScaled(img, context);
                

                const c = canvasRef.current;
                if(c){
                    const imgData = maskContext.getImageData(0, 0, c.width, c.height);
                    let transparentPixels = 0
                    for (let i = 0; i < imgData.data.length; i += 4) {
                        if (imgData.data[i + 3] === 0) {
                            transparentPixels++
                        };
                    }
                    const totalPixels = c.width * c.height;
                    const percent = Math.round((1 - (1 / totalPixels * transparentPixels)) * 100);
                    // console.log('UNCOVERED:', totalPixels, transparentPixels, percent)
                    setUncoveredPercentage(percent);

                }
            }

        }
    },[img, setUncoveredPercentage, uncoveredPercentage, stencilS, canvasMask, FINISHED_UNCOVERED_PERCENTAGE]);

    const handleMouseMove = useCallback((event: MouseEvent<HTMLCanvasElement>)=>{
        if(event.buttons !== 1) return;
        if(!prize.status || prize.status === '') return;
        const localPos = getLocalPos({x:event.clientX, y:event.clientY},event.currentTarget);
        draw(localPos.x, localPos.y);
    },[draw, prize])

    const handleTouchMove = useCallback((event: React.TouchEvent<HTMLCanvasElement>)=>{
        if(!prize.status || prize.status === '') return;
        if (event.changedTouches && event.changedTouches.length) {
            const touch = event.changedTouches[0];
            const localPos = getLocalPos({x:touch.clientX, y:touch.clientY}, event.currentTarget);
            draw(localPos.x, localPos.y);
          }
    },[draw, prize])

    const getLocalPos = (pos: {x:number,y:number}, target: HTMLElement) => {
        let rect = target.getBoundingClientRect()
        let x = pos.x - rect.left; //x position within the element.
        let y = pos.y - rect.top;  //y position within the element.
        return {x,y}
      }

    return <canvas 
        ref={canvasRef}
        style={{'background': `url(${imageScratchingPath})`, 'border':'4px solid white', 'borderRadius': '3px'}}
        width={width}
        height={height}
        onMouseMove={ ev => handleMouseMove(ev) }
        onTouchMove={ ev => handleTouchMove(ev) }
    />
}