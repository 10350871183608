import React, { FC } from "react"
import './style.css'

interface Props {
    children?: React.ReactNode;
    onClick: () => void;
}

const Button: FC<Props> = ({children, onClick}) => {
    return (
        <button onClick={onClick} className="btn">
            {children}
        </button>
    )
}

export default Button