import { Fragment, useEffect, useState } from 'react';
import Button from '../../components/Button';
import { useRequestData } from '../../service/requestService';
import { useSnipesApp } from '../../SnipesApp';
import CopySvg from '../../svg/CopySvg';
import './style.css';

const WinPage = () => {
  const SnipesApp = useSnipesApp();

  const { prize, couponTxt, requestSecondCall, prizeOptions } = useRequestData()
  const [copyToClipBoardClass, setcopyToClipBoardClass] = useState('')

  useEffect(() => {
    requestSecondCall()
  },[requestSecondCall])

  const onCopyToClipboar = () => {
    navigator.clipboard.writeText(couponTxt).then(function() {
      setcopyToClipBoardClass('copied');
      setTimeout(()=>{setcopyToClipBoardClass('')},1000)
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  const onClick = () => {
    SnipesApp.navigate('CategoryLandingPage', {category: {id:'mcd', name:'mcd'}})
  }

  const getPrizeOption = prizeOptions[prize.type]

  const renderPrize = () => prize.type !== '' ? `product_win/${prize.type}.jpg` : 'loose.jpg'

  return (
    <Fragment>
      <img className="win-copy" src="you_win.png" alt="" />
      <div className="win-container">
        <img src={renderPrize()} alt="loose img" className="win-img"/>
        <div className="win-prize-copy">
          <p className="win-prize-copy__header">
            {getPrizeOption[0]}
          </p>
          <p className="win-prize-copy__description">
            {getPrizeOption[1]}
          </p>
        </div>
      </div>

      {
        couponTxt === ''
        &&(
        <p className="action-text win-action-text">
          Dein Gewinn wird dir an deine hinterlegte Adresse gesendet. Sollte diese Adresse nicht stimmen, kontaktiere uns bitte über app@snipes.com.
        </p>)
      }

      {
        couponTxt !== ''
        && (
          <p
            onClick={() => onCopyToClipboar()}
            className={`win-code-coupon ${copyToClipBoardClass}`}
          >
            {couponTxt}
            <CopySvg />
          </p>
        )
      }

      {
        prize.type === 'SNI_VOUCHER_10'
        && (
        <p className="action-text win-action-text">
          Speicher den Code zusätzlich auf deinem Gerät ab!
        </p>
        )
      }

      {
        couponTxt !== ''
        && (
        <p className="terms-conditions-text win-action-terms-conditions-text">
          *Gutschein einlösbar über snipes.com oder über die SNIPES App bis zum 12.12.2021. Einzelne Artikel ausgenommen.
        </p>
        )
      }

      <Button onClick={onClick}>
        Zur Kollektion
      </Button>
    </Fragment> 
  )
}

export default WinPage;