import { Fragment, useEffect } from 'react';
import { ScratchCanvas } from '../../components/ScratchCanvas';
import { useRequestData } from '../../service/requestService';
import { useUIEvents } from '../../service/uiService';
import { useSnipesApp } from '../../SnipesApp';
import './style.css';

const IntroPage = () => {
  const { FINISHED_UNCOVERED_PERCENTAGE, setCurrentView, uncoveredPercentage } = useUIEvents();
  const { prize } = useRequestData()
  const SnipesApp = useSnipesApp();

  useEffect(()=> {
    if (uncoveredPercentage >= FINISHED_UNCOVERED_PERCENTAGE) {
      setTimeout(()=>{
        if(prize.type === 'LOSS'){
          setCurrentView('LOSE');
        } else {
          setCurrentView('WIN');
        }
      },2000)
    }
  },[FINISHED_UNCOVERED_PERCENTAGE, uncoveredPercentage, setCurrentView, prize]);

  const renderScratch = () => {
    if(SnipesApp) {
      return (
          <ScratchCanvas
            imagePricePath= {renderPrize()}
            imageScratchingPath='scratch-field.jpg'
            width={200}
            height={200}
          />)
    }

    return (<p className="not-supported-message">
        Bitte aktualisiere deine SNIPES App um teilzunehmen!
      </p>)
  }

  const renderPrize = () => {
   return prize.type !== 'LOSS' && prize.status !== '' ? `product_scratching/${prize.type}.jpg` : 'loose.jpg'
  }

  const openContentPage = () => {
    SnipesApp.navigate('Content Page', {
      page: {
        url: 'https://www.snipes.com/content/win.html',
        prevRoute: 'Feed',
      },
    }) 
  }

  return (
    <Fragment>
      <img className="action-image" src="scratch_it_up.png" alt="" />
      <p className="description-text">
        Jetzt einmalig das Feld freiscratchen und mit etwas Glück einen von über <span className="description-text__highlight">10.000 Preisen und Gutscheinen gewinnen</span>.
      </p>
      <div className="scrath-canvas">
        { renderScratch() }
      </div>
      <p className="action-text">
        Scratche bis der Inhalt sichtbar ist.
      </p>
      <p className="terms-conditions-text" onClick={() => openContentPage()}>
        Mit der Teilnahme am Gewinnspiel akzeptierst du unsere Teilnahmebedingungen. 
      </p>
    </Fragment>
  )
}

export default IntroPage;