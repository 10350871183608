import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useEffect } from 'react';
import './App.css';
import { useSnipesApp } from './SnipesApp';
import { useRequestData, customerObj } from './service/requestService';
import { useUIEvents } from "./service/uiService";
import IntroPage from './views/Intro';
import LoosePage from "./views/Loose";
import WinPage from "./views/Win";

function App() {
  const { currentView, setCurrentView} = useUIEvents();
  const { prize, requestPrize, setJWToken, requestDone, setRequestDone, setURLRequest } = useRequestData();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const debug = urlParams.get('debug') !== null ? true : false;

  // Test
  if (debug) {
    /* @ts-ignore */
    window.SnipesApp = customerObj;
  }

  let SnipesApp = useSnipesApp();

  useEffect(() => {
    if (SnipesApp && SnipesApp.customer.auth_type !== 'registered') {
      alert('Du musst dich anmelden oder registrieren, um dieses Features zu nutzen.')
      SnipesApp.navigate('Intro');
    }
  }, [SnipesApp])

  useEffect(() => {
    setURLRequest(debug);

    if (SnipesApp && SnipesApp.customer && !requestDone) {
      setJWToken(SnipesApp.customer.JWT);
      requestPrize();
      setRequestDone();
    }
  },[SnipesApp, requestPrize, setJWToken, debug, setRequestDone, requestDone, setURLRequest])

  useEffect(() => {
    switch(prize.status) {
      case 'PENDING':
        setCurrentView('INTRO');
        break;
      case 'REVEALED':
        if(prize.type !== 'LOSS') {
          setCurrentView('WIN');
        } else {
          setCurrentView('LOSE');
        }
        break;
      default:
        if (!SnipesApp && !requestDone)
          setCurrentView('INTRO');
    }
  },[prize, setCurrentView, SnipesApp, requestDone])

  return (
    <div className="App">
      <img className="mcxsp-logo" src="logo.png" alt="" />
      <SwitchTransition>
        <CSSTransition
          key={currentView}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="content-container">
            {currentView === 'INTRO' && <IntroPage />}
            {currentView === 'WIN' && <WinPage />}
            {currentView === 'LOSE' && <LoosePage />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default App;
