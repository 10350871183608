import create from 'zustand'

export const useUIEvents = create(set => ({
  FINISHED_UNCOVERED_PERCENTAGE: 85,
  uncoveredPercentage: 0,
  currentView: '',
  setUncoveredPercentage: status => {
    set({uncoveredPercentage: status})
  },
  setCurrentView: view => {
    set({currentView: view})
  }
}))