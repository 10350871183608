import { Fragment, useEffect } from 'react';
import Button from '../../components/Button';
import { useRequestData } from '../../service/requestService';
import { useSnipesApp } from '../../SnipesApp';
import './style.css';

const LoosePage = () => {
  const { requestSecondCall } = useRequestData()
  const SnipesApp = useSnipesApp();

  useEffect(() => {
    requestSecondCall()
  },[requestSecondCall])

  const onClick = () => {
    SnipesApp.navigate('CategoryLandingPage', {category: {id:'mcd', name:'mcd'}})
  }

  return (
    <Fragment>
      <img className="action-image" src="loose.png" alt="" />
      <p className="description-text">
        Leider hattest du keinen Erfolg.<br/>Schau Dir doch trotzdem unsere <span className="description-text__highlight"> SNIPES x McDonald's Kollektion </span>an.
      </p>
      <img src="Lose@1x.jpg" alt="loose img" className="loose-img"/>
      <Button onClick={onClick}>
        Zur Kollektion
      </Button>
    </Fragment> 
  )
}

export default LoosePage;